.header-container {
  height: 10vh;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: sticky;
  justify-content: center;
  top: 0;
}

.header-block {
  width: 70%;
  display: flex;
  align-items: center;
}

ul {
  height: 100%;
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: sticky;
  top: 0;
}

.header-ul {
  width: 33.3%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.header-menu {
  width: 33.4%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-menu li {
  width: 33.3%;
  display: flex;
  justify-content: center;
}

.header-menu li:first-child {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.header-menu li:last-child {
  width: 40%;
  display: flex;
  justify-content: flex-start;
}

.header-menu li {
  margin: 0;
}

.header-links {
  width: 33.3%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-links li {
  margin: 0;
}

.header-menu a {
  text-decoration: none;
  color: black;
}

li {
  display: flex;
  align-items: center;
  margin: 1em;
}

.header-ul li {
  display: block;
  text-decoration: none;
  color: black;
  margin: 0;
}

.header-name {
  display: flex;
  align-items: center;
}

.header-name a {
  text-decoration: none;
  color: black;
}

/* .selected {
  background-color: black;
} */

.selected a {
  color: white;
}


.instagram-logo a img {
  width: 20px;
  height: 20px;
}

.vimeo-logo a img {
  width: 20px;
  height: 20px;
  margin: 1em;
}

.invert {
  -webkit-filter: invert(100%);
}

@media screen and (max-width: 768px) {
  .header-container {
    height: 7vh;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .header-block {
    width: 90%;
    margin: 0 auto;
  }

  .header-ul {
    width: 0;
    transform: translateX(-100%);
    position: fixed;
    top: 0;
  }

  .header-links {
    width: 0;
    transform: translateX(100%);
  }

  .header-menu {
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .header-menu li:first-child {
    text-align: left;
  }

  .mobile {
    z-index: 10;
    background-color: white;
  }
}
