* {
  font-family: Arial;
  font-size: 14px;
}

.container {
  transition: opacity 0.5s ease-in-out;
  text-align: center;
  height: 90vh;
}

.app-container {
  transition: background-color 0.5s ease-in-out;
}

.background-image {
  transition: opacity 0.5s ease-in-out;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
  overflow: hidden;
}

#myVideo {
  width: 100%;
  height: 100%;
  z-index: -1; /* Send video to the background */
  object-fit: cover;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.list-container {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  font-size: 14px;
}

.video-container {
  width: 100%;
  margin: 0 auto;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: black;
}

.list {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.list-item {
  margin: 10px 0;
  display: flex;
  justify-content: center;
}

.video-button,
.back-button {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
}

.video-button {
  margin: 0;
  padding: 0;
}

.video-button:hover {
  color: white;
}

.back-button {
  color: white;
}

.selected {
  background-color: black;
}

@media screen and (max-width: 768px) {
  .container {
    height: 100%;
    text-align: left;
  }

  .list {
    width: 90%;
    margin: 0;
  }

  .list-item {
    display: flex;
    flex-direction: column;
  }

  .video-button {
    width: 100%;
    display: flex;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
  }

  .video-container {
    height: 93vh;
  }

  .video-preview-container {
    width: 100%;
  }

  .video-preview {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
