.contact-container {
  height: 90vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-container a {
  text-decoration: none;
  color: black;
}

.contact-box {
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-text {
  margin-bottom: 40px;
}

.contact-table {
  width: 100%;
}

.contact-table-title {
  width: 20%;
  font-weight: bold;
}

.contact-table-text {
  width: 80%;
}

.contact-table tr {
  height: 100px;
}

.contact-back-button {
  display: flex;
  position: absolute;
  bottom: 5%;
  right: 15%;
}

@media screen and (max-width: 768px) {
  .contact-container {
    height: 93vh;
  }

  .contact-box {
    width: 90%;
  }

  .contact-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .contact-table-title {
    width: 50%;
  }
  
  .contact-table-text {
    width: 50%;
  }

  .contact-back-button {
    display: flex;
    position: absolute;
    bottom: 5%;
    right: 10%;
  }
}
